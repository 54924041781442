import React, { useContext } from "react";
import * as S from "./OrderSummary.styles";
import Context from "../../context/Context";
import { useOrdersData } from "../../hooks/useOrdersData";

const OrderSummaryToggle = ({ children }) => {
  const { showOrderSummary, setShowOrderSummary } = useContext(Context);

  const { hasItems } = useOrdersData();

  const onClickHandler = () => {
    setShowOrderSummary(!showOrderSummary);
  };

  return hasItems ? (
    <S.ToggleContainer onClick={onClickHandler}>{children}</S.ToggleContainer>
  ) : null;
};

export default OrderSummaryToggle;
